section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-container>*:last-child:not(.content), .content-container>.content>*:last-child{
    padding-bottom: 3em;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.grey {
    background-color: var(--accent-3-light);
}

.blue {
    background-color: var(--accent-1-extra-light);
}

.yellow {
    background-color: var(--accent-2-lighter);
}

.section-content {
    max-width: 1200px;
    padding: 1em;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-content>div {
    width: 100%;
}

.section-content h1 {
    margin: 1em 0;
}

.one-column h1, .two-column>.center h1 {
    text-align: center;
}

.two-column {
    display: flex;
    flex-direction: row;
}

.two-column:not(.narrow) {
    justify-content: space-between;
}

.two-column.narrow {
    justify-content: space-around;
}

.two-column:not(.narrow)>* {
    flex: 1;
    margin: 0;
}

.center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.additions {
    margin-right: auto;
    margin-left: auto;
}


.two-column>.center>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.center>div>.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.alignleft {
    margin-left: 0;
    margin-right: auto;
}

.alignright {
    margin-left: auto;
    margin-right: 0;
}

.aligncenter {
    margin: 0;
}

.alignjustify {
    margin-left: 0;
    margin-right: 0;
}

.text-container>div>div {
    margin-bottom: 1em;
    overflow-x: auto;

}

.section-content .additions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
}

@media (min-width: 600px) {
    .two-column>*:first-child {
        padding-right: 1em;
    }
    .two-column>*:last-child {
        padding-left: 1em;
    }

    .one-column.narrow {
        max-width: 60%
    }

    .one-column:not(.narrow) .image-gallery:not(.fullscreen-modal) {
        max-width: 60%;
    }
}

@media (max-width: 600px) {
    .two-column>*:first-child {
        padding-bottom: 0.5em;
    }
    .two-column>*:last-child {
        padding-top: 0.5em;
    }
    .two-column {
        flex-direction: column;
    }
}