div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content>div {
    width: 100%
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

div.content div.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3em;
}

.section-container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-container.centered{
    padding: 0.5em 0.5em;
}

.justified{
    text-align: justify;
}


.text-content {
    padding-left: 1em;
}

.photo {
    background-size: cover;
    background-position: center;
    width: 100%;
}

:not(.thin).photo, :not(thick).photo {
    height: 300px;
}

.thick.photo {
    height: 400px;
}

.thin.photo {
    height: 200px;
}

.spacer {
    height: 3em;
}

.grey {
    background-color: var(--accent-3-light);
}

@media (max-width: 1130px) {
    :not(.thin).photo, :not(thick).photo {
        height: 200px;
    }

    .thick.photo {
        height: 300px;
    }

    .thin.photo {
        height: 100px;
    }
}

@media (max-width: 600px) {
    .centered {
        width: auto;
    }

    .multicolumn {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

}

/* home page */

.achievements-slider-container {
    margin: 1em 0 2em 0;
    width: 100%;
}

.achievements-slider-container>div>div>div>div>div.slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 175px;
}

.achievements-slider-object-container {
    max-height: 150px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.achievements-slider-object {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    object-fit: contain;
    max-height: 150px;
    max-width: 130px;
    cursor: pointer;
    background-color: var(--white-1);
}

.fullscreen-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: black;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fullscreen-container>img {
    max-height: 80%;
    max-width: 80%;
    object-fit: contain;
    padding-bottom: 5%;
}

.fullscreen-container>a {
    max-height: 10%
}

.photo-gallery-slider-object {
    width: 100%;
    height: 500px;
    object-fit: cover;

}

.photo-gallery-slider {
    width: 100%;
    height: 500px;
    margin-bottom: 25px;
}

#close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.2em;
}

div.content div.shortcut-menu-page {
    width: 60%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    padding-bottom: 0 !important;
}

@media (max-width: 700px) {
    div.content div.shortcut-menu-page {
        width: 90%;
    }
}

div.content div.shortcut-menu-page div#shortcut {
    width: 100%;
}

div.content div.shortcut-menu-page div#shortcut a {
    min-width: 140px;
    padding: 0 10px;
}

div.content div#shortcut {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 2em;
    align-content: stretch;
}

div.content div#shortcut a{
    min-width: 160px;
    margin: 10px 10px;
    padding: 5px 20px;
    flex: 1;
    background-color: var(--accent-1);
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    border-radius: 10px;
    color: var(--white-1);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.content div#shortcut a:hover {
    background-color: var(--accent-1-light);
    box-shadow: 0 6px 10px 0 rgba(15, 49, 126, 0.2), 0 8px 22px 0 rgba(15, 49, 126, 0.19);
    color: var(--white-1);
}


div.content div#shortcut a div h3 {
    text-align: center;
}

div.content div.vertical-centered>div#shortcut{
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.content div.vertical-centered>div#shortcut>a{
    width: 50%;
    min-width: 200px;
}

@media (max-width: 800px) {
    div.content div.vertical-centered>div#shortcut>a{
        width: 70%;
    }

    .photo-gallery-slider {
        height: 200px;
    }

    .photo-gallery-slider-object {
        height: 200px;
    }
}

@media (max-width: 400px) {
    div.content div.vertical-centered>div#shortcut>a{
        width: 85%;
    }
}

/* table styling */
table {
    margin: 1px;
}

table, table td {
    margin: auto;
    border-collapse: collapse;
}

table td{
    padding: 0.3em 0.7em;
    border: 1px solid var(--accent-1);
    border-collapse: collapse;
}


table th {
    background-color: var(--accent-2-light);
    font-weight: bold;
    text-align: center;
    padding: 0.7em 0.7em;
}

table td * {
    margin: 0;
}

@media (max-width: 600px) {

    table {
        max-width: 95%;
        width: 95% !important;
        font-size: 1em;
    }
}

table.no-border-table {
    font-size: 1em;
    border: none;
    width: auto;
    margin: 0;
}

table.no-border-table td {
    border: none;
    width: auto;
    height: auto !important;
    padding: 0.3em 0.5em 0.3em 0;
    vertical-align: top;
}