@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap');

    #menu {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        text-transform: uppercase;
        font-size: medium;
        width: 100%;
        font-family: Rowdies, sans-serif;
    }

    #menu * {
        text-decoration: none;
    }

    #menu > * {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-lines: 1;
        flex: 0 1 25%;
    }

    #menu > *:not(:last-child)::after {
        content: '';
        position: absolute;
        right: -2px;
        top: 25%;
        width: 1px;
        height: 50%;
        background-color: var(--white-1);
    }

    .menu-item a, .menu-item:visited a, .menu-item path, .menu-item:visited path {
        color: var(--white-1);
    }

    .menu-item svg * {
        fill: var(--white-1)
    }

    .menu-item:hover a, .menu-item:hover path {
        color: var(--accent-2-light);
        fill: var(--accent-2-light);
    }

    .menu-item:active a, .menu-item:active path {
        color: var(--accent-2-light);
        fill: var(--accent-2-light);
        font-weight: normal;
    }


    .menu-item {
        height: 100%;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.8em;
        padding-right: 0.8em;
    }

    .menu-item svg {
        height: 1.2em;
        width: auto;
        align-self: center;
        padding: 0;
    }

.menu-item:not(.hovered) .submenu:not(.hovered) {
        display: none;
        z-index: 15;
    }

    /*.menu-item:hover .submenu {*/
    /*    top: 100%;*/
    /*    left: 0;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    position: absolute;*/
    /*    font-size: medium;*/
    /*    background-color: var(--accent-2-light);*/
    /*    padding-top: 0.3em;*/
    /*    padding-bottom: 0.5em;*/
    /*}*/

    /*.submenu-item {*/
    /*    white-space: pre-wrap;*/
    /*}*/

    /*.menu-item:hover .submenu-item {*/
    /*    color: var(--accent-1);*/
    /*    padding: 0.4em 0.7em;*/
    /*    width: 15em;*/
    /*}*/

    /*.menu-item:hover .submenu-item:hover {*/
    /*    color: var(--accent-1-light);*/
    /*}*/

.menu-item.hovered .submenu {
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: medium;
    background-color: var(--accent-2-light);
    padding-top: 0.3em;
    padding-bottom: 0.5em;
}

.submenu-item {
    white-space: pre-wrap;
}

.menu-item.hovered .submenu .submenu-item {
    color: var(--accent-1);
    padding: 0.4em 0.7em;
    width: 15em;
}

.menu-item.hovered .submenu .submenu-item:hover {
    color: var(--accent-1-light);
}

    @media (min-width: 1131px) {
        .submenu {
            box-shadow: 0 4px 8px 0 rgba(105, 89, 5, 0.2), 0 6px 20px 0 rgba(105, 89, 5, 0.19);
        }
    }

.disabled-link {
    pointer-events: none;
}