@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap');


#side-menu .menu-item-active, .menu-item-active>* {
        color: var(--accent-1-light) !important;
        fill: var(--accent-1-light) !important;
    }

    #side-menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 18em;
        background-color: var(--accent-2-light);
        box-shadow: -8px 0 8px 0 rgba(105, 89, 5, 0.2), -20px 0 20px 0 rgba(105, 89, 5, 0.19);
        z-index: 5;
        padding-top: var(--nav-bar-height);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    #side-menu-container {
        overflow-x: hidden;

    }

#side-menu .accessibility-setting {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 0 1em;
    background-color: var(--accent-2-light);
}

#side-menu .accessibility-setting>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#side-menu .accessibility-setting>div>p {
    font-family: Nunito, SansSerif;
    text-transform: capitalize;
    color: var(--font-dark);
}

#side-menu .accessibility-setting>div>*:not(p) {
    font-size: larger;
    margin: 0 0.3em;
    fill: var(--font-dark);
    vertical-align: bottom;
    font-family: Rowdies, SansSerif, serif;
}

#side-menu .accessibility-setting>div>div>span:first-of-type {
    margin-right: 0.5em;
}

#side-menu .accessibility-setting>div>*:not(p) * {
    vertical-align: baseline;
}

#side-menu .accessibility-setting svg {
    height: 1.3em;
}

    #side-menu-container>div,
    #side-menu-container>div>a.side-menu-item,
    #side-menu-container details,
    #side-menu-container div.side-menu-item>a {
        width: 100%;
        box-sizing: border-box;
    }

    #side-menu-container, summary {
        font-family: Rowdies, sans-serif;
        text-transform: uppercase;
        color: var(--accent-1);
    }

    .side-menu-anim-enter {
        transform: translateX(100vw);
        opacity: 1;
        transition: all 300ms linear;
    }
    .side-menu-anim-enter-active {
        transform: translateX(0);
        opacity: 1;
        transition: all 300ms linear;
    }
    .side-menu-anim-exit {
        transform: translateX(0);
        opacity: 1;
        transition: all 300ms linear;

    }
    .side-menu-anim-exit-active {
        transform: translateX(100vw);
        opacity: 1;
        transition: all 300ms linear;
    }

    #side-menu * {
        text-decoration: none;
    }

    #side-menu a {
        color: var(--accent-1);

    }

    #side-menu-container>div {
        display: flex;
        align-items: center;
        padding: 1em;
    }

#side-menu summary::-webkit-details-marker {
        display: none;
    }

#side-menu summary:focus {
        outline-style: none;
    }

    #side-menu .submenu {
        display: flex;
        flex-direction: column;
        padding-top: 0.3em;
        padding-bottom: 0.5em;
        font-size: 0.9em;
    }

    .side-menu-item .submenu-item {
        color: var(--accent-1);
        padding: 0.5em 1em;
        width: 15em;
    }



    .side-menu-item .submenu-item:hover {
        color: var(--accent-1-light);
    }
