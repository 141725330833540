.interactive-photo-container {
    margin: 1em 0;
    width: 80%;
    position: relative;
    height: auto;
    max-height: 100vh;
}

.interactive-photo-container>figure {
    width: 100%;
    height: 100%;
    z-index: 1;
    margin: 0;

}

.interactive-photo-container>figure>img {
    width: 100%;
    height: 100%;
    display: block;
}

.source {
    position: absolute;
    bottom: 0.5em;
    right: 1em;
    height: auto;
    z-index: 2;
    font-size: smaller;
    box-sizing: border-box;
}

.interactive-photo-container svg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    z-index: 2;
}

.interactive-photo-container .dim {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: var(--accent-1-lightly-faded);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: fadeOut 5s;
    animation: fadeOut 5s;
    animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1;}
    80% { opacity: 1;}
    99% { opacity: 0.01; width: 100%; height: 100%;}
    100% { opacity: 0; width: 0; height: 0;}
}

@keyframes fadeOut {
    0% { opacity: 1;}
    80% { opacity: 1;}
    99% { opacity: 0.01; width: 100%; height: 100%;}
    100% { opacity: 0; width: 0; height: 0;}
}

.dim>h1 {
    color:var(--white-1);
    font-weight: normal;
    margin: 0;
    text-align: center;
}

.interactive-photo-container svg path {
    cursor: pointer;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.interactive-photo-container svg path:not(.clicked) {
    stroke-width: 0;
    fill: transparent;
}


.interactive-photo-container svg path:hover:not(.clicked) {
    stroke-width: 30;
    fill: var(--accent-2-faded);
    stroke: var(--accent-2-light);
}

path.clicked, .interactive-photo-container svg path:active, .interactive-photo-container svg path:focus{
    stroke-width: 30;
    fill: var(--accent-2-lightly-faded);
    stroke: var(--accent-2-light);
}

.hovered-area {
    height: 3em;
    width: 23em;
    margin-bottom: 3em;
    max-width: 100%;
}

.hovered-area>h1 {
    margin: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-2-light);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.area-info .oembed-container {
    max-width: 80%;
}

@media (max-width: 750px) {
    .area-info .oembed-container {
        max-width: 95%;
    }
}

.area-info, .area-info .image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.area-info div>div.image-gallery img.image-gallery-image, div.area-info>div>img {
    object-fit: contain;

}

.area-info .image-gallery-content {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 80%;
}

.area-info .image-gallery-slides img{
    object-fit: cover;
    object-position: center;
    max-height: 50vh;
}


.area-info .oembed-container {
    margin: 1em 0;
    width: 95%;
    display: flex;
    justify-content: center;
}

@media (max-width: 750px) {
    .interactive-photo-container {
        width: 100%;
    }

    .area-info .image-gallery-content {
        max-width: 95%;
    }
}