@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap);
:root {
    --font-dark: rgb(19, 15, 83);
    --white-1: rgb(246, 246, 246);
    --white-2: rgb(242, 242, 242);
    --accent-1: rgb(65, 111, 216);
    --accent-1-light: rgb(122, 158, 239);
    --accent-1-extra-light: rgb(195, 215, 252);
    --accent-1-faded: rgba(65, 111, 216, 0.36);
    --accent-1-lightly-faded: rgba(65, 111, 216, 0.61);
    --accent-2: rgb(238, 204, 35);
    --accent-2-light: rgb(241, 218, 105);
    --accent-2-lighter: rgb(238, 225, 160);
    --accent-2-faded: rgba(238, 204, 35, 0.38);
    --accent-2-lightly-faded: rgba(238, 204, 35, 0.53);
    --accent-3: rgba(112, 112, 112, 0.78);
    --accent-3-light: rgba(232, 232, 232, 1.0);

    --min-width-wide-pc: 1200px;
    --min-width-pc: 1130px;
    --min-width-tablet: 600px;

    --nav-bar-height: 0;
}

html {
    min-height: 100vh;
    -webkit-text-size-adjust: 100%;
}
body {
    font-family: "Nunito", sans-serif;
    min-width: 360px;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: rgb(246, 246, 246);
    background-color: var(--white-1);
    color: rgb(19, 15, 83);
    color: var(--font-dark);
    font-size: 15px;
}


#root {
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    /*align-items: stretch;*/
    justify-content: space-between;
}

.content-container {
    flex: 2 1;
}

@media (max-width: 1130px) {
    .content-container {
        margin-top: 4em;
    }
}

*:focus {
    outline-style: none;
}

button.button-accent-1, button.button-accent-2 {
    height: auto;
    border-radius: 50px;
    padding: 8px 30px;
    font-family: "Rowdies", sans-serif;
    font-size: smaller;
    text-transform: uppercase;
    white-space: nowrap;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

button.button-accent-1 {
    background-color: rgb(65, 111, 216);
    background-color: var(--accent-1);
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    color: rgb(246, 246, 246);
    color: var(--white-1);
}

button.button-accent-1:hover {
    background-color: rgb(122, 158, 239);
    background-color: var(--accent-1-light);
}

button.button-accent-2 {
    background-color: rgb(238, 204, 35);
    background-color: var(--accent-2);
    box-shadow: 0 4px 8px 0 rgba(105, 89, 5, 0.2), 0 6px 20px 0 rgba(105, 89, 5, 0.19);
    color: rgb(19, 15, 83);
    color: var(--font-dark);
}

button.button-accent-2:hover {
    background-color: rgb(241, 218, 105);
    background-color: var(--accent-2-light);
}

div.post h2{
    font-family: "Nunito", sans-serif;
    text-transform: none;

}

h1, h2, h3 {
    font-family: "Rowdies", sans-serif;
    text-transform: uppercase;
}

h1 {
    font-size: 1.2em;
    margin-top: 2em;
    margin-bottom: 1em;
}

h2 {
    font-size: 1.1em;
}

h3 {
    font-size: 1em;
}

small {
    font-size: 0.7em;
    font-weight: 300;
}

input {
    font-family: "Nunito", sans-serif;
    color: rgb(19, 15, 83) !important;
    color: var(--font-dark) !important;
}

::-webkit-input-placeholder {
    color: rgb(65, 111, 216);
    color: var(--accent-1);
}
::placeholder {
    color: rgb(65, 111, 216);
    color: var(--accent-1);
}

a {
    cursor: pointer;
}

a:link, a:visited {
    color: rgb(65, 111, 216);
    color: var(--accent-1);
}

a:hover {
    color: rgb(122, 158, 239);
    color: var(--accent-1-light);
}

a:active {
    color: rgb(65, 111, 216);
    color: var(--accent-1);
}

a.attachment, a.link{
    margin: 0.5em 0;
    padding: 0.5em 1em;
    color: rgb(19, 15, 83);
    color: var(--font-dark);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
}

a.link {
    background-color: rgb(195, 215, 252);
    background-color: var(--accent-1-extra-light);
}

a.attachment{
    background-color: rgb(238, 225, 160);
    background-color: var(--accent-2-lighter);
    z-index: 1;
}


a.attachment p, a.link p {
    font-size: smaller;
    margin: 0 1em 0 0;
    display: inline-block;
    text-align: left;
    color: rgb(19, 15, 83);
    color: var(--font-dark);
    word-break: break-word;
}

a.attachment svg, a.link svg {
    height: 1.2em;
    width: 1.2em;
    margin-left: 10px;
}

a.attachment svg * , a.link svg * {
    fill: rgb(19, 15, 83);
    fill: var(--font-dark);
    color: rgb(19, 15, 83);
    color: var(--font-dark);
}
a.attachment div, a.link div {
    display: flex;
    align-items: center;
    justify-content: center;
}


a.attachment:hover *, a.link:hover * {
    color: rgb(65, 111, 216);
    color: var(--accent-1);
    fill: rgb(65, 111, 216);
    fill: var(--accent-1);
}


.menu-item-active, .menu-item-active *{
    color: rgb(238, 204, 35) !important;
    color: var(--accent-2) !important;
    fill: rgb(238, 204, 35) !important;
    fill: var(--accent-2) !important;
}

.image-button {
    cursor: pointer;
}

.image-button svg {
    height: 1.2em;
    width: auto;
}

.image-button:hover * {
    color: rgb(238, 204, 35);
    color: var(--accent-2);
    fill: rgb(238, 204, 35);
    fill: var(--accent-2);
    stroke: rgb(238, 204, 35);
    stroke: var(--accent-2);
}

#spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4em;
    padding-bottom: 4em;
}

input {
    background-color: rgb(246, 246, 246);
    background-color: var(--white-1);
}

.video-responsive {
    padding: 20px;
    width: 72%;
    height: 500px;
    max-width: 940px;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

@media (max-width: 800px) {
    .video-responsive {
        height: 200px;
        width: 90%;
    }
}
header {
    top: 0;
    left: 0;
    right: 0;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    flex-direction: column;
    flex: 0 1;
    z-index: 5;
}

header p {
    margin: auto;
}

header #ribbon a {
    text-decoration: none;
    color: var(--font-dark)
}
header #ribbon a:hover {
    text-decoration: none;
    color: var(--accent-1)
}

#navigation-bar {
    position: relative;
    background-color: var(--accent-1);
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    order: 1;
}

#navigation-bar, #navigation-bar * {
    z-index: 10;
}

#wrapper {
    position: relative;
}

header .container {
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

#navigation-bar .container {
    align-items: stretch;
    z-index: 10;
}

#navigation-bar #mobile-menu{
    display: none;
}

/* Showcase */

#navigation-bar #showcase {
    display: flex;
    flex-direction: row;
}

#showcase>a {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navigation-bar #showcase svg{
    margin: 0.5em;
    width: 100px;
    height: auto;
    display: flex;
    align-items: center;
}


#navigation-bar #showcase>div{
    color: var(--white-1);
    font-family: Rowdies, sans-serif;
    font-weight: lighter;
    font-size: medium;
    align-self: center;
}

/* Menu container = contact ribbon + menu */

#menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

/* Contact ribbon */

#navigation-bar #ribbon {
    padding: 0.5em 1em;
    border-bottom-left-radius: 10em;
    border-bottom-right-radius: 10em;
    background-color: var(--accent-2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    font-size: smaller;
}


#ribbon>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-margin-start: 0.5em;
            margin-inline-start: 0.5em;
}

#ribbon>div>div, #ribbon>div>a {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-margin-end: 0.5em;
            margin-inline-end: 0.5em;
}

#ribbon>div>a {
    height: 1.5em
}

/*#ribbon a {*/
/*    height: 1.5em;*/
/*    margin: 0;*/
/*}*/

#ribbon svg {
    height: 1.5em;
    margin: 0;
    vertical-align: bottom;
}

#ribbon a svg path, #ribbon span svg path{
    fill: var(--font-dark);
}

#ribbon a svg:hover path, #ribbon span svg:hover path {
    fill: var(--accent-1);
}

#ribbon span:hover {
    color: var(--accent-1);
}

#ribbon .accessibility-setting>span {
    font-family: Rowdies, sans-serif;
    font-size: larger;
    vertical-align: bottom;
    cursor: pointer;
}

#ribbon .accessibility-setting>span:not(:last-of-type) {
    margin: 0 0.2em

}

/* searchbar */

.search-bar-anim-enter {
    -webkit-transform: translateY(calc(0px - var(--nav-bar-height)));
            transform: translateY(calc(0px - var(--nav-bar-height)));
    opacity: 1;
    transition: all 300ms linear;
}
.search-bar-anim-enter-active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    transition: all 300ms linear;
}
.search-bar-anim-exit {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    transition: all 300ms linear;

}
.search-bar-anim-exit-active {
    -webkit-transform: translateY(calc(0px - var(--nav-bar-height)));
            transform: translateY(calc(0px - var(--nav-bar-height)));
    opacity: 1;
    transition: all 300ms linear;
}

.wiggling{
    -webkit-animation: wiggle 0.5s;
            animation: wiggle 0.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes wiggle {
    0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-0.5deg); transform: translate(-1px, -2px) rotate(-0.5deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(0.5deg); transform: translate(-3px, 0px) rotate(0.5deg); }
    30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(0.5deg); transform: translate(1px, -1px) rotate(0.5deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-0.5deg); transform: translate(-1px, 2px) rotate(-0.5deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(3px, 1px) rotate(-0.5deg); transform: translate(3px, 1px) rotate(-0.5deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(0.5deg); transform: translate(-1px, -1px) rotate(0.5deg); }
    90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-0.5deg); transform: translate(1px, -2px) rotate(-0.5deg); }
}

@keyframes wiggle {
    0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-0.5deg); transform: translate(-1px, -2px) rotate(-0.5deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(0.5deg); transform: translate(-3px, 0px) rotate(0.5deg); }
    30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(0.5deg); transform: translate(1px, -1px) rotate(0.5deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-0.5deg); transform: translate(-1px, 2px) rotate(-0.5deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(3px, 1px) rotate(-0.5deg); transform: translate(3px, 1px) rotate(-0.5deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(0.5deg); transform: translate(-1px, -1px) rotate(0.5deg); }
    90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-0.5deg); transform: translate(1px, -2px) rotate(-0.5deg); }
}

#search-bar {
    background-color: var(--accent-1-light);
    padding: 0.5em;
    text-align: center;
    position: fixed;
    top: calc(var(--nav-bar-height) - 1px);
    left: 0;
    right: 0;
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    order: 2;
    z-index: 3;
}

#search-bar .container {
    align-items: center;
}

#search-bar #spacer {
    flex: 1 1;
}

#search-bar #form {
    flex: 0 1;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
}

#search-bar #input {
    -webkit-appearance: none;
    border-style: none;
    border-radius: 50px;
    width: 30em;
    padding: 7px 15px;
    margin-right: 1em;
    font-size: 0.9em;
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
}

@media (max-width: 1130px) {
    #search-bar #input {
        font-size: 16px;
    }
}

#search-bar #cross-container {
    flex: 1 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#cross {
    height: 100%;
    display: flex;
    align-items: center;
}

#search-bar #cross>* {
    height: 1.2em;
    padding-right: 0.4em;
    fill: var(--white-1);

}

#search-bar #cross:hover svg * {
    stroke: var(--accent-2-light);
}
/* smaller pc */

@media (max-width: 1200px) {
    #navigation-bar #ribbon {
        border-bottom-right-radius: 0;
    }
}

/* tablet */

#navigation-bar #mobile-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-right: 0.5em;
}

#navigation-bar #mobile-menu>* {
    height: 1.7em;
    width: 1.7em;
    margin: 1em;
    stroke-width: 7;
    color: var(--white-1);
}

#burger {
    height: var(--nav-bar-height);
    width: auto;
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 1.5em;
    padding-left: .5em;
}

#navigation-bar #mobile-menu svg path {
    fill: var(--white-1);
}
@media (max-width: 1130px) {

    #menu-container {
        display: none;
    }

    header {
        position: fixed;
    }

    #navigation-bar {
        overflow-x: hidden;
    }

    #navigation-bar #showcase svg {
        width: 70px;
    }

    #navigation-bar #showcase>div {
        font-size: smaller;
    }

}

/* phone */

@media (max-width: 675px) {
    #search-bar {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }

    #search-bar #form {
        flex: 1 1;
    }

    #search-bar #input {
        width: 100%;
        margin-right: 0.4em;
    }

    #search-bar .button-accent-2 {
        padding: 8px 15px;
        display: flex;
        align-items: center;
    }

    #search-bar .button-accent-2>* {
        height: auto;
        width: 1.5em;
        align-items: center;
        align-self: center;

    }

    #search-bar #spacer {
        display: none;
    }

    #search-bar #cross-container {
        display: none;
    }
}

@media (max-width: 600px) {

    #navigation-bar #showcase svg {
        margin: 0;
    }

    #navigation-bar #showcase>div {
        margin-left: 0.6em;
        font-size: x-small;
    }

    #navigation-bar #showcase {
        margin: 0.5em;
    }

    #navigation-bar #mobile-menu>* {
        height: 1.5em;
        width: 1.5em;
        margin: 0.7em;
        stroke-width: 7;
    }

    #burger {
        padding-right: 1.2em;
    }

    #navigation-bar #mobile-menu>*>div {
        width: 1.5em;
    }

    #navigation-bar #mobile-menu svg path {
        stroke-width: 7;
    }

    #navigation-bar #mobile-menu{
        justify-content: flex-end;
        position: relative;
    }
}
#menu {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        text-transform: uppercase;
        font-size: medium;
        width: 100%;
        font-family: Rowdies, sans-serif;
    }

    #menu * {
        text-decoration: none;
    }

    #menu > * {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        max-lines: 1;
        flex: 0 1 25%;
    }

    #menu > *:not(:last-child)::after {
        content: '';
        position: absolute;
        right: -2px;
        top: 25%;
        width: 1px;
        height: 50%;
        background-color: var(--white-1);
    }

    .menu-item a, .menu-item:visited a, .menu-item path, .menu-item:visited path {
        color: var(--white-1);
    }

    .menu-item svg * {
        fill: var(--white-1)
    }

    .menu-item:hover a, .menu-item:hover path {
        color: var(--accent-2-light);
        fill: var(--accent-2-light);
    }

    .menu-item:active a, .menu-item:active path {
        color: var(--accent-2-light);
        fill: var(--accent-2-light);
        font-weight: normal;
    }


    .menu-item {
        height: 100%;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.8em;
        padding-right: 0.8em;
    }

    .menu-item svg {
        height: 1.2em;
        width: auto;
        align-self: center;
        padding: 0;
    }

.menu-item:not(.hovered) .submenu:not(.hovered) {
        display: none;
        z-index: 15;
    }

    /*.menu-item:hover .submenu {*/
    /*    top: 100%;*/
    /*    left: 0;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    position: absolute;*/
    /*    font-size: medium;*/
    /*    background-color: var(--accent-2-light);*/
    /*    padding-top: 0.3em;*/
    /*    padding-bottom: 0.5em;*/
    /*}*/

    /*.submenu-item {*/
    /*    white-space: pre-wrap;*/
    /*}*/

    /*.menu-item:hover .submenu-item {*/
    /*    color: var(--accent-1);*/
    /*    padding: 0.4em 0.7em;*/
    /*    width: 15em;*/
    /*}*/

    /*.menu-item:hover .submenu-item:hover {*/
    /*    color: var(--accent-1-light);*/
    /*}*/

.menu-item.hovered .submenu {
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    font-size: medium;
    background-color: var(--accent-2-light);
    padding-top: 0.3em;
    padding-bottom: 0.5em;
}

.submenu-item {
    white-space: pre-wrap;
}

.menu-item.hovered .submenu .submenu-item {
    color: var(--accent-1);
    padding: 0.4em 0.7em;
    width: 15em;
}

.menu-item.hovered .submenu .submenu-item:hover {
    color: var(--accent-1-light);
}

    @media (min-width: 1131px) {
        .submenu {
            box-shadow: 0 4px 8px 0 rgba(105, 89, 5, 0.2), 0 6px 20px 0 rgba(105, 89, 5, 0.19);
        }
    }

.disabled-link {
    pointer-events: none;
}
#side-menu .menu-item-active, .menu-item-active>* {
        color: var(--accent-1-light) !important;
        fill: var(--accent-1-light) !important;
    }

    #side-menu {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 18em;
        background-color: var(--accent-2-light);
        box-shadow: -8px 0 8px 0 rgba(105, 89, 5, 0.2), -20px 0 20px 0 rgba(105, 89, 5, 0.19);
        z-index: 5;
        padding-top: var(--nav-bar-height);
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
    }

    #side-menu-container {
        overflow-x: hidden;

    }

#side-menu .accessibility-setting {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 0 1em;
    background-color: var(--accent-2-light);
}

#side-menu .accessibility-setting>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#side-menu .accessibility-setting>div>p {
    font-family: Nunito, SansSerif;
    text-transform: capitalize;
    color: var(--font-dark);
}

#side-menu .accessibility-setting>div>*:not(p) {
    font-size: larger;
    margin: 0 0.3em;
    fill: var(--font-dark);
    vertical-align: bottom;
    font-family: Rowdies, SansSerif, serif;
}

#side-menu .accessibility-setting>div>div>span:first-of-type {
    margin-right: 0.5em;
}

#side-menu .accessibility-setting>div>*:not(p) * {
    vertical-align: baseline;
}

#side-menu .accessibility-setting svg {
    height: 1.3em;
}

    #side-menu-container>div,
    #side-menu-container>div>a.side-menu-item,
    #side-menu-container details,
    #side-menu-container div.side-menu-item>a {
        width: 100%;
        box-sizing: border-box;
    }

    #side-menu-container, summary {
        font-family: Rowdies, sans-serif;
        text-transform: uppercase;
        color: var(--accent-1);
    }

    .side-menu-anim-enter {
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
        opacity: 1;
        transition: all 300ms linear;
    }
    .side-menu-anim-enter-active {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
        transition: all 300ms linear;
    }
    .side-menu-anim-exit {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
        transition: all 300ms linear;

    }
    .side-menu-anim-exit-active {
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
        opacity: 1;
        transition: all 300ms linear;
    }

    #side-menu * {
        text-decoration: none;
    }

    #side-menu a {
        color: var(--accent-1);

    }

    #side-menu-container>div {
        display: flex;
        align-items: center;
        padding: 1em;
    }

#side-menu summary::-webkit-details-marker {
        display: none;
    }

#side-menu summary:focus {
        outline-style: none;
    }

    #side-menu .submenu {
        display: flex;
        flex-direction: column;
        padding-top: 0.3em;
        padding-bottom: 0.5em;
        font-size: 0.9em;
    }

    .side-menu-item .submenu-item {
        color: var(--accent-1);
        padding: 0.5em 1em;
        width: 15em;
    }



    .side-menu-item .submenu-item:hover {
        color: var(--accent-1-light);
    }

div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content>div {
    width: 100%
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

div.content div.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3em;
}

.section-container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-container.centered{
    padding: 0.5em 0.5em;
}

.justified{
    text-align: justify;
}


.text-content {
    padding-left: 1em;
}

.photo {
    background-size: cover;
    background-position: center;
    width: 100%;
}

:not(.thin).photo, :not(thick).photo {
    height: 300px;
}

.thick.photo {
    height: 400px;
}

.thin.photo {
    height: 200px;
}

.spacer {
    height: 3em;
}

.grey {
    background-color: var(--accent-3-light);
}

@media (max-width: 1130px) {
    :not(.thin).photo, :not(thick).photo {
        height: 200px;
    }

    .thick.photo {
        height: 300px;
    }

    .thin.photo {
        height: 100px;
    }
}

@media (max-width: 600px) {
    .centered {
        width: auto;
    }

    .multicolumn {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

}

/* home page */

.achievements-slider-container {
    margin: 1em 0 2em 0;
    width: 100%;
}

.achievements-slider-container>div>div>div>div>div.slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 175px;
}

.achievements-slider-object-container {
    max-height: 150px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.achievements-slider-object {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    object-fit: contain;
    max-height: 150px;
    max-width: 130px;
    cursor: pointer;
    background-color: var(--white-1);
}

.fullscreen-container {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: black;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fullscreen-container>img {
    max-height: 80%;
    max-width: 80%;
    object-fit: contain;
    padding-bottom: 5%;
}

.fullscreen-container>a {
    max-height: 10%
}

.photo-gallery-slider-object {
    width: 100%;
    height: 500px;
    object-fit: cover;

}

.photo-gallery-slider {
    width: 100%;
    height: 500px;
    margin-bottom: 25px;
}

#close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.2em;
}

div.content div.shortcut-menu-page {
    width: 60%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    padding-bottom: 0 !important;
}

@media (max-width: 700px) {
    div.content div.shortcut-menu-page {
        width: 90%;
    }
}

div.content div.shortcut-menu-page div#shortcut {
    width: 100%;
}

div.content div.shortcut-menu-page div#shortcut a {
    min-width: 140px;
    padding: 0 10px;
}

div.content div#shortcut {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 2em;
    align-content: stretch;
}

div.content div#shortcut a{
    min-width: 160px;
    margin: 10px 10px;
    padding: 5px 20px;
    flex: 1 1;
    background-color: var(--accent-1);
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    border-radius: 10px;
    color: var(--white-1);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.content div#shortcut a:hover {
    background-color: var(--accent-1-light);
    box-shadow: 0 6px 10px 0 rgba(15, 49, 126, 0.2), 0 8px 22px 0 rgba(15, 49, 126, 0.19);
    color: var(--white-1);
}


div.content div#shortcut a div h3 {
    text-align: center;
}

div.content div.vertical-centered>div#shortcut{
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.content div.vertical-centered>div#shortcut>a{
    width: 50%;
    min-width: 200px;
}

@media (max-width: 800px) {
    div.content div.vertical-centered>div#shortcut>a{
        width: 70%;
    }

    .photo-gallery-slider {
        height: 200px;
    }

    .photo-gallery-slider-object {
        height: 200px;
    }
}

@media (max-width: 400px) {
    div.content div.vertical-centered>div#shortcut>a{
        width: 85%;
    }
}

/* table styling */
table {
    margin: 1px;
}

table, table td {
    margin: auto;
    border-collapse: collapse;
}

table td{
    padding: 0.3em 0.7em;
    border: 1px solid var(--accent-1);
    border-collapse: collapse;
}


table th {
    background-color: var(--accent-2-light);
    font-weight: bold;
    text-align: center;
    padding: 0.7em 0.7em;
}

table td * {
    margin: 0;
}

@media (max-width: 600px) {

    table {
        max-width: 95%;
        width: 95% !important;
        font-size: 1em;
    }
}

table.no-border-table {
    font-size: 1em;
    border: none;
    width: auto;
    margin: 0;
}

table.no-border-table td {
    border: none;
    width: auto;
    height: auto !important;
    padding: 0.3em 0.5em 0.3em 0;
    vertical-align: top;
}


#error-image {
    margin-top: 8em;
    width: 60%;
    height: auto;
}

#error div>a {
    padding-top: 7em;
    padding-bottom: 3em;
}
ul.pagination {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    list-style: none;
    padding-left: 0;
}

ul.pagination li {
    margin: 5px;
}

ul.pagination li:first-of-type,
ul.pagination li:last-of-type {
    margin: 5px 20px;
}

ul.pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-family: "Rowdies", sans-serif;
    font-size: 1em;
    text-transform: uppercase;
    border: none;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    cursor: pointer;
    background-color: var(--accent-1);
    color: var(--white-1);
}

@media (max-width: 700px) {
    ul.pagination li {
        margin: 3px;
    }

    ul.pagination li:first-of-type,
    ul.pagination li:last-of-type {
        margin: 3px 10px;
    }

    ul.pagination li a {
        width: 30px;
        height: 30px;
    }
}

ul.pagination li.active a {
    background-color: var(--accent-1-light);
}

ul.pagination li a:hover {
    background-color: var(--accent-1-extra-light);
}

ul.pagination li:first-of-type a,
ul.pagination li:last-of-type a {
    width: 30px;
    height: 30px;
    font-family: "Nunito", sans-serif;
    font-size: larger;
    font-weight: bolder;
    background: none;
    box-shadow: none;
    color: var(--accent-1);
}

ul.pagination li.disabled a {
    color: var(--accent-1-light);
}

ul.pagination li:first-of-type:not(.disabled) a:hover,
ul.pagination li:last-of-type:not(.disabled) a:hover {
    color: var(--accent-1-light);
}
div.posts-container{
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 5% 30px 5%;
}

div.post {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    max-height: 210px;
    margin-bottom: 30px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.543s ease-in-out;
}

div.post:last-child{
    margin-bottom: 0;
}

div.post.post-left {
    flex-direction: row-reverse;
}

div.post.post-empty {
    height: 210px;
    width: 80%;
}

.post-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.post>div {
    margin: 10px;
    flex-direction: column;
}

div.post div.post-image-container {
    flex: 1 1;
    min-width: 250px;
    overflow: hidden;
}

div.post div>div.image-gallery img.image-gallery-image {
    height: 130px;
    object-fit: cover;
}

div.post div>div.image-gallery.single img.image-gallery-image {
    height: 175px;
    object-fit: cover;
}

div.post div.post-content-container{
    flex: 2 1;
    min-width: 250px;
}

div.post div.post-content-container h2 {
    margin: 0 0 5px;
}

div.post div.post-content-container small.post-date {
    margin-bottom: 1em;
}

div.post div.post-content-container p {
    flex: 2 1;
}

div.post div.post-content-container>p * {
    font-size: medium;
}

.post-text {
    margin-bottom: 3em;
    overflow: hidden;
}

.post-text, .post-text * {
    font-size: 1em !important;
    text-align: left !important;
}

.post-text img {
    max-width: 100%;
    object-fit: scale-down;
}

.post-text div.after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top, var(--white-1) 30%, rgba(255,255,255, 0) 70%);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.543s ease-in-out;
}

.disabled-content {
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}

div.post div.post-content-container div.post-text>div:first-of-type>* {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
}

div.post div.post-content-container div.post-text a.post-attachment:first-of-type {
    margin-top: 1.5em;
}

div.post div.post-content-container div.post-text a.post-attachment:last-of-type {
    margin-bottom: 1.5em;
}

div.post button.post-button{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10em;
    margin: 20px;
}

div.post.post-left button.post-button {
    right: unset;
    left: 0;
}

div.post iframe {
    max-width: 100%;
    width: initial;
    height: initial;
}

@media only screen and (min-width: 1600px) {
    div.post div.post-content-container {
        flex: 4 1;
    }
}

@media only screen and (max-width: 1130px) {
    .news{
        /*padding-bottom: 4em;*/
    }
}

@media only screen and (max-width: 960px) {
    div.post,
    div.post.post-empty{
        width: 80%;
    }
}

@media only screen and (max-width: 800px) {
    div.post,
    div.post.post-empty {
        width: 90%;
        padding: 2px;
    }

    div.posts-container {
        width: 95%;
    }
}

@media only screen and (max-width: 636px) {
    div.post {
        max-height: 420px;
    }

    .post-text div.after {
        background: linear-gradient(to top, var(--white-1) 30%, rgba(255, 255, 255, 0) 70%);
        height: 40%;
    }

    div.post.post-left button.post-button {
        right: 0;
        left: unset;
    }

    div.post div.post-image-container > div {
        flex: 1 1;
    }

    div.post div.post-image-container > div {
        min-width: 70px;
        /*min-height: 190px;*/
    }

    div.post div > div.image-gallery img.image-gallery-image {
        height: 230px;
    }

    div.post div>div.image-gallery.single img.image-gallery-image {
        height: 260px;
    }
}

@media only screen and (max-width: 550px) {
    div.post, div.post-empty {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    div.post div > div.image-gallery img.image-gallery-image {
        height: 170px;
    }

    div.post div>div.image-gallery.single img.image-gallery-image {
        height: 200px;
    }
}

@media only screen and (max-width: 370px) {
    div.post div.post-content-container {
        min-width: 200px;
    }
}
div>div.image-gallery{
    width: 100%;
}

div>div.image-gallery * {
    outline: none !important;
}

div.image-gallery-slides div {
    width: 100%;
}

div>div.image-gallery.single .image-gallery-left-nav,
div>div.image-gallery.single .image-gallery-right-nav,
div>div.image-gallery.single .image-gallery-play-button,
div>div.image-gallery.single .image-gallery-thumbnails-wrapper,
div>div.image-gallery.single .image-gallery-thumbnails-wrapper *{
    display: none !important;
}

.image-gallery-svg {
    z-index: 0;
}

div>div.image-gallery.fullscreen-modal {
    padding: 0.5em 0;
}

div>div.image-gallery .fullscreen img.image-gallery-image {
    max-height: 85vh;
    width: auto !important;
    max-width: 100%;
    height: auto !important;
}

div>div.image-gallery div.image-gallery-thumbnails-wrapper {
    height: auto;
}

div>div.image-gallery div.image-gallery-thumbnails-container {
    justify-content: center;
}

div>div.image-gallery div.image-gallery-thumbnails-container button {
    flex: 1 1;
    height: 45px;
    width: 45px;
}

div>div.image-gallery .fullscreen div.image-gallery-thumbnails-container button {
    flex: 1 1;
    height: unset;
    width: unset;
}

.image-gallery-thumbnail {
    width: 45px;
}

/* .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    max-height: 100%;
    max-width: 100%;
} */

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

/*icons*/

.image-gallery-svg:focus, .image-gallery-svg:active {
    stroke: var(--white-1);
}

div>div.image-gallery .image-gallery-left-nav,
div>div.image-gallery .image-gallery-right-nav{
    padding: 0;
}

div>div.image-gallery .image-gallery-left-nav .image-gallery-svg,
div>div.image-gallery .image-gallery-right-nav .image-gallery-svg{
    height: 60px;
    width: 30px;
    margin: 0 4px;
}

div>div.image-gallery .image-gallery-fullscreen-button,
div>div.image-gallery .image-gallery-play-button {
    padding: 5px 10px;
}

div>div.image-gallery .image-gallery-fullscreen-button .image-gallery-svg,
div>div.image-gallery .image-gallery-play-button .image-gallery-svg {
    height: 20px;
    width: 20px;
}


@media screen and (min-width: 500px) {
    div>div.image-gallery .fullscreen .image-gallery-left-nav,
    div>div.image-gallery .fullscreen .image-gallery-right-nav{
        padding: 3px;
    }

    div>div.image-gallery .fullscreen .image-gallery-left-nav .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-right-nav .image-gallery-svg{
        height: 90px;
        width: 45px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-play-button .image-gallery-svg {
        height: 28px;
        width: 28px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button,
    div>div.image-gallery .fullscreen .image-gallery-play-button {
        padding: 8px 15px;
    }
}

@media screen and (min-width: 900px) {
    div>div.image-gallery .fullscreen .image-gallery-left-nav,
    div>div.image-gallery .fullscreen .image-gallery-right-nav{
        padding: 15px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button,
    div>div.image-gallery .fullscreen .image-gallery-play-button {
        padding: 20px 30px;
    }
}

@media screen and (min-width: 1130px) {
    div>div.image-gallery .fullscreen .image-gallery-left-nav,
    div>div.image-gallery .fullscreen .image-gallery-right-nav{
        padding: 10px;
    }

    div>div.image-gallery .fullscreen .image-gallery-left-nav .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-right-nav .image-gallery-svg{
        height: 120px;
        width: 60px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-play-button .image-gallery-svg {
        height: 36px;
        width: 36px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button,
    div>div.image-gallery .fullscreen .image-gallery-play-button {
        padding: 20px 30px;
    }

    div>div.image-gallery .image-gallery-icon:hover {
        color: var(--accent-2-light);
    }
}

div>div.image-gallery .image-gallery-thumbnail.active,
div>div.image-gallery .image-gallery-thumbnail:hover,
div>div.image-gallery .image-gallery-thumbnail:focus {
    border-color: var(--accent-1);
}

@media only screen and (min-width: 1300px) {
    .post div>div.image-gallery {
        width: 100%;
        min-width: 200px;
    }
}
#map {
    width: 100%;
    height: 20em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
}

.map-container {
    position: relative;
    height: 20em;
    width: 100%;
    max-width: 1200px;
}

#info-window {
    padding: 1em;
}

.section-container #logo-container {
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#logo-container>img {
    height: 150px;
    object-fit: contain;
}
section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-container>*:last-child:not(.content), .content-container>.content>*:last-child{
    padding-bottom: 3em;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.grey {
    background-color: var(--accent-3-light);
}

.blue {
    background-color: var(--accent-1-extra-light);
}

.yellow {
    background-color: var(--accent-2-lighter);
}

.section-content {
    max-width: 1200px;
    padding: 1em;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-content>div {
    width: 100%;
}

.section-content h1 {
    margin: 1em 0;
}

.one-column h1, .two-column>.center h1 {
    text-align: center;
}

.two-column {
    display: flex;
    flex-direction: row;
}

.two-column:not(.narrow) {
    justify-content: space-between;
}

.two-column.narrow {
    justify-content: space-around;
}

.two-column:not(.narrow)>* {
    flex: 1 1;
    margin: 0;
}

.center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.additions {
    margin-right: auto;
    margin-left: auto;
}


.two-column>.center>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.text-container {
    display: flex;
    flex-direction: column;
}

.center>div>.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.alignleft {
    margin-left: 0;
    margin-right: auto;
}

.alignright {
    margin-left: auto;
    margin-right: 0;
}

.aligncenter {
    margin: 0;
}

.alignjustify {
    margin-left: 0;
    margin-right: 0;
}

.text-container>div>div {
    margin-bottom: 1em;
    overflow-x: auto;

}

.section-content .additions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
}

@media (min-width: 600px) {
    .two-column>*:first-child {
        padding-right: 1em;
    }
    .two-column>*:last-child {
        padding-left: 1em;
    }

    .one-column.narrow {
        max-width: 60%
    }

    .one-column:not(.narrow) .image-gallery:not(.fullscreen-modal) {
        max-width: 60%;
    }
}

@media (max-width: 600px) {
    .two-column>*:first-child {
        padding-bottom: 0.5em;
    }
    .two-column>*:last-child {
        padding-top: 0.5em;
    }
    .two-column {
        flex-direction: column;
    }
}
div.water-examine-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.water-examine-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

a.water-examine-attachment {
    width: 50%;
}


@media (max-width: 700px) {
    div.water-examine-container {
        width: 80%;
    }
}

@media (max-width: 500px) {
    div.water-examine-container {
        width: 90%;
    }

    a.water-examine-attachment {
        width: 70%;
    }
}

@media (max-width: 400px) {
    div.water-examine-container {
        width: 100%;
    }
}


div.content.menu-page h1 {
    margin-top: 1em;
}
div.post-page>div.section-container {
    width: 60%;
    margin: 3em 0;
}
div.post-page div.image-gallery-container {
    width: 100%;
}

div.post-page div.image-gallery-container>div.image-gallery img.image-gallery-image,
div.post-page div.image-gallery-container>div.image-gallery.single img.image-gallery-image {
    height: 400px;
    object-fit: cover;
}

div.post-page div.post-content-container {
    width: 100%;
    margin: 1em 0;
}

div.post-page div.post-content-container h2.post-title {
    font-family: Nunito, sans-serif;
    margin-bottom: 5px;
    text-transform: none;
}

div.post-page div.post-content-container div.post-text {
    margin-bottom: 0;
}

div.post-page div.post-content-container div.post-text>div:first-of-type>* {
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
}

div.post-page div.post-content-container div.post-attachments {
    margin: 2.5em 0;
}

/*div.post-page div.post-content-container div.post-text div.post-attachments a.post-attachment:first-of-type {*/
/*    margin-top: 1.5em;*/
/*}*/

@media only screen and (max-width: 1130px) {
    div.post-page div.image-gallery-container>div.image-gallery img.image-gallery-image,
    div.post-page div.image-gallery-container>div.image-gallery.single img.image-gallery-image {
        height: 300px;
    }
}

@media only screen and (max-width: 900px) {
    div.post-page>div.section-container {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    div.post-page div.image-gallery-container>div.image-gallery img.image-gallery-image,
    div.post-page div.image-gallery-container>div.image-gallery.single img.image-gallery-image {
        height: 200px;
    }
}

@media only screen and (max-width: 500px) {
    div.post-page>div.section-container {
        width: 95%;
    }
}
.interactive-photo-container {
    margin: 1em 0;
    width: 80%;
    position: relative;
    height: auto;
    max-height: 100vh;
}

.interactive-photo-container>figure {
    width: 100%;
    height: 100%;
    z-index: 1;
    margin: 0;

}

.interactive-photo-container>figure>img {
    width: 100%;
    height: 100%;
    display: block;
}

.source {
    position: absolute;
    bottom: 0.5em;
    right: 1em;
    height: auto;
    z-index: 2;
    font-size: smaller;
    box-sizing: border-box;
}

.interactive-photo-container svg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    z-index: 2;
}

.interactive-photo-container .dim {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: var(--accent-1-lightly-faded);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: fadeOut 5s;
    animation: fadeOut 5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes fadeOut {
    0% { opacity: 1;}
    80% { opacity: 1;}
    99% { opacity: 0.01; width: 100%; height: 100%;}
    100% { opacity: 0; width: 0; height: 0;}
}

@keyframes fadeOut {
    0% { opacity: 1;}
    80% { opacity: 1;}
    99% { opacity: 0.01; width: 100%; height: 100%;}
    100% { opacity: 0; width: 0; height: 0;}
}

.dim>h1 {
    color:var(--white-1);
    font-weight: normal;
    margin: 0;
    text-align: center;
}

.interactive-photo-container svg path {
    cursor: pointer;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.interactive-photo-container svg path:not(.clicked) {
    stroke-width: 0;
    fill: transparent;
}


.interactive-photo-container svg path:hover:not(.clicked) {
    stroke-width: 30;
    fill: var(--accent-2-faded);
    stroke: var(--accent-2-light);
}

path.clicked, .interactive-photo-container svg path:active, .interactive-photo-container svg path:focus{
    stroke-width: 30;
    fill: var(--accent-2-lightly-faded);
    stroke: var(--accent-2-light);
}

.hovered-area {
    height: 3em;
    width: 23em;
    margin-bottom: 3em;
    max-width: 100%;
}

.hovered-area>h1 {
    margin: 0;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-2-light);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

.area-info .oembed-container {
    max-width: 80%;
}

@media (max-width: 750px) {
    .area-info .oembed-container {
        max-width: 95%;
    }
}

.area-info, .area-info .image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.area-info div>div.image-gallery img.image-gallery-image, div.area-info>div>img {
    object-fit: contain;

}

.area-info .image-gallery-content {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 80%;
}

.area-info .image-gallery-slides img{
    object-fit: cover;
    object-position: center;
    max-height: 50vh;
}


.area-info .oembed-container {
    margin: 1em 0;
    width: 95%;
    display: flex;
    justify-content: center;
}

@media (max-width: 750px) {
    .interactive-photo-container {
        width: 100%;
    }

    .area-info .image-gallery-content {
        max-width: 95%;
    }
}
div.content-search div.section h1 {
    margin-bottom: 0;
}

div.content-search div.section h3 {
    font-family: "Nunito", sans-serif;
    text-transform: unset;
}

div.content-search div.section div.results-container {
    width: 90%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.content-search div.section div.results-container div.result {
    width: 50%;
    margin: 10px 0;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.content-search div.section div.results-container div.result:last-of-type {
    margin-bottom: 20px;
}

div.content-search div.section div.results-container div.result h2 {
    font-family: "Nunito", sans-serif;
    text-transform: unset;
    margin-bottom: 0.5em;
}

div.content-search div.section div.results-container div.result p {
    margin: 0 0 0.83em 1em;
    font-size: smaller;
}

@media (max-width: 1130px) {
    div.content-search div.section div.results-container div.result {
        width: 70%;
    }
}

@media (max-width: 600px) {
    div.content-search div.section div.results-container div.result {
        width: 90%;
    }
}
footer {
    bottom: 0;
    width: 100%;
    box-shadow: 0 -4px 8px 0 rgba(15, 49, 126, 0.2), 0 -6px 20px 0 rgba(15, 49, 126, 0.19);
    flex: 0 1;
    font-size: smaller;
    z-index: 1;
    color: var(--white-1);
}

footer p {
    margin: auto;


}

footer h3 {
    margin-bottom: 5px;
}

footer .contact a {
    text-decoration: none;
}

footer #top {
    background-color: var(--accent-1-light);
    padding: 20px;
    box-sizing: border-box;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.footer-container>div {
    flex: 1 1;
}

footer :not(#links)>div>p>a:link {
    text-decoration: none;
}

footer a:link, footer a:visited {
    color: var(--white-1);
}

footer a:hover {
    color: var(--accent-2-light);
}

footer #showcase {
    display: flex;
}

footer #showcase svg{
    width: 90px;
    height: auto;
    margin-right: 5px;
    align-self: center;
}

footer #showcase>div{
    align-self: center;
}

footer #emblem {
    align-self: center;
    flex: 0 1;
}

footer #emblem img {
    height: 150px;
    margin: 0;
    align-self: center;
}

footer #top .contact {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

footer #top .contact #labels>p {
    margin-right: 1em;
}

footer #top .contact>div>p>span {
    display: none;
}

footer #top #links {
    text-align: right;
}

footer #top #links h3 {
    -webkit-margin-before: 0;
            margin-block-start: 0;
}

footer #top #links a svg {
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
}

footer #bottom {
    padding: 10px;
    background-color: var(--accent-1);
}

footer #divider {
    height: 0.5em;
    background-color: var(--accent-2-light);
}

footer #bottom #authors {
    text-align: right;
}


@media (max-width: 970px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    footer #top #links h3 {
        -webkit-margin-before: 1em;
                margin-block-start: 1em;
    }

    footer * {
        justify-content: center;
        text-align: center;
        align-self: center;
    }

    footer #top #showcase {
        flex-direction: column;
    }

    footer #top #showcase img{
        margin: 0;
    }

    footer #top .contact #labels  {
        display: none;
    }

    footer #top .contact>div>p>span {
        display: inline;
    }

    footer #emblem img{
        display: none;
    }
}
