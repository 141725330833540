#map {
    width: 100%;
    height: 20em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
}

.map-container {
    position: relative;
    height: 20em;
    width: 100%;
    max-width: 1200px;
}

#info-window {
    padding: 1em;
}

.section-container #logo-container {
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

#logo-container>img {
    height: 150px;
    object-fit: contain;
}