header {
    top: 0;
    left: 0;
    right: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    flex: 0;
    z-index: 5;
}

header p {
    margin: auto;
}

header #ribbon a {
    text-decoration: none;
    color: var(--font-dark)
}
header #ribbon a:hover {
    text-decoration: none;
    color: var(--accent-1)
}

#navigation-bar {
    position: relative;
    background-color: var(--accent-1);
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    order: 1;
}

#navigation-bar, #navigation-bar * {
    z-index: 10;
}

#wrapper {
    position: relative;
}

header .container {
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

#navigation-bar .container {
    align-items: stretch;
    z-index: 10;
}

#navigation-bar #mobile-menu{
    display: none;
}

/* Showcase */

#navigation-bar #showcase {
    display: flex;
    flex-direction: row;
}

#showcase>a {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navigation-bar #showcase svg{
    margin: 0.5em;
    width: 100px;
    height: auto;
    display: flex;
    align-items: center;
}


#navigation-bar #showcase>div{
    color: var(--white-1);
    font-family: Rowdies, sans-serif;
    font-weight: lighter;
    font-size: medium;
    align-self: center;
}

/* Menu container = contact ribbon + menu */

#menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

/* Contact ribbon */

#navigation-bar #ribbon {
    padding: 0.5em 1em;
    border-bottom-left-radius: 10em;
    border-bottom-right-radius: 10em;
    background-color: var(--accent-2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    font-size: smaller;
}


#ribbon>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-start: 0.5em;
}

#ribbon>div>div, #ribbon>div>a {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-inline-end: 0.5em;
}

#ribbon>div>a {
    height: 1.5em
}

/*#ribbon a {*/
/*    height: 1.5em;*/
/*    margin: 0;*/
/*}*/

#ribbon svg {
    height: 1.5em;
    margin: 0;
    vertical-align: bottom;
}

#ribbon a svg path, #ribbon span svg path{
    fill: var(--font-dark);
}

#ribbon a svg:hover path, #ribbon span svg:hover path {
    fill: var(--accent-1);
}

#ribbon span:hover {
    color: var(--accent-1);
}

#ribbon .accessibility-setting>span {
    font-family: Rowdies, sans-serif;
    font-size: larger;
    vertical-align: bottom;
    cursor: pointer;
}

#ribbon .accessibility-setting>span:not(:last-of-type) {
    margin: 0 0.2em

}

/* searchbar */

.search-bar-anim-enter {
    transform: translateY(calc(0px - var(--nav-bar-height)));
    opacity: 1;
    transition: all 300ms linear;
}
.search-bar-anim-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms linear;
}
.search-bar-anim-exit {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms linear;

}
.search-bar-anim-exit-active {
    transform: translateY(calc(0px - var(--nav-bar-height)));
    opacity: 1;
    transition: all 300ms linear;
}

.wiggling{
    animation: wiggle 0.5s;
    animation-iteration-count: infinite;
}

@keyframes wiggle {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-0.5deg); }
    20% { transform: translate(-3px, 0px) rotate(0.5deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(0.5deg); }
    50% { transform: translate(-1px, 2px) rotate(-0.5deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-0.5deg); }
    80% { transform: translate(-1px, -1px) rotate(0.5deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-0.5deg); }
}

#search-bar {
    background-color: var(--accent-1-light);
    padding: 0.5em;
    text-align: center;
    position: fixed;
    top: calc(var(--nav-bar-height) - 1px);
    left: 0;
    right: 0;
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    order: 2;
    z-index: 3;
}

#search-bar .container {
    align-items: center;
}

#search-bar #spacer {
    flex: 1;
}

#search-bar #form {
    flex: 0;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
}

#search-bar #input {
    -webkit-appearance: none;
    border-style: none;
    border-radius: 50px;
    width: 30em;
    padding: 7px 15px;
    margin-right: 1em;
    font-size: 0.9em;
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
}

@media (max-width: 1130px) {
    #search-bar #input {
        font-size: 16px;
    }
}

#search-bar #cross-container {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#cross {
    height: 100%;
    display: flex;
    align-items: center;
}

#search-bar #cross>* {
    height: 1.2em;
    padding-right: 0.4em;
    fill: var(--white-1);

}

#search-bar #cross:hover svg * {
    stroke: var(--accent-2-light);
}
/* smaller pc */

@media (max-width: 1200px) {
    #navigation-bar #ribbon {
        border-bottom-right-radius: 0;
    }
}

/* tablet */

#navigation-bar #mobile-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-right: 0.5em;
}

#navigation-bar #mobile-menu>* {
    height: 1.7em;
    width: 1.7em;
    margin: 1em;
    stroke-width: 7;
    color: var(--white-1);
}

#burger {
    height: var(--nav-bar-height);
    width: auto;
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 1.5em;
    padding-left: .5em;
}

#navigation-bar #mobile-menu svg path {
    fill: var(--white-1);
}
@media (max-width: 1130px) {

    #menu-container {
        display: none;
    }

    header {
        position: fixed;
    }

    #navigation-bar {
        overflow-x: hidden;
    }

    #navigation-bar #showcase svg {
        width: 70px;
    }

    #navigation-bar #showcase>div {
        font-size: smaller;
    }

}

/* phone */

@media (max-width: 675px) {
    #search-bar {
        padding-left: 0.4em;
        padding-right: 0.4em;
    }

    #search-bar #form {
        flex: 1;
    }

    #search-bar #input {
        width: 100%;
        margin-right: 0.4em;
    }

    #search-bar .button-accent-2 {
        padding: 8px 15px;
        display: flex;
        align-items: center;
    }

    #search-bar .button-accent-2>* {
        height: auto;
        width: 1.5em;
        align-items: center;
        align-self: center;

    }

    #search-bar #spacer {
        display: none;
    }

    #search-bar #cross-container {
        display: none;
    }
}

@media (max-width: 600px) {

    #navigation-bar #showcase svg {
        margin: 0;
    }

    #navigation-bar #showcase>div {
        margin-left: 0.6em;
        font-size: x-small;
    }

    #navigation-bar #showcase {
        margin: 0.5em;
    }

    #navigation-bar #mobile-menu>* {
        height: 1.5em;
        width: 1.5em;
        margin: 0.7em;
        stroke-width: 7;
    }

    #burger {
        padding-right: 1.2em;
    }

    #navigation-bar #mobile-menu>*>div {
        width: 1.5em;
    }

    #navigation-bar #mobile-menu svg path {
        stroke-width: 7;
    }

    #navigation-bar #mobile-menu{
        justify-content: flex-end;
        position: relative;
    }
}