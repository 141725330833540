div.post-page>div.section-container {
    width: 60%;
    margin: 3em 0;
}
div.post-page div.image-gallery-container {
    width: 100%;
}

div.post-page div.image-gallery-container>div.image-gallery img.image-gallery-image,
div.post-page div.image-gallery-container>div.image-gallery.single img.image-gallery-image {
    height: 400px;
    object-fit: cover;
}

div.post-page div.post-content-container {
    width: 100%;
    margin: 1em 0;
}

div.post-page div.post-content-container h2.post-title {
    font-family: Nunito, sans-serif;
    margin-bottom: 5px;
    text-transform: none;
}

div.post-page div.post-content-container div.post-text {
    margin-bottom: 0;
}

div.post-page div.post-content-container div.post-text>div:first-of-type>* {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

div.post-page div.post-content-container div.post-attachments {
    margin: 2.5em 0;
}

/*div.post-page div.post-content-container div.post-text div.post-attachments a.post-attachment:first-of-type {*/
/*    margin-top: 1.5em;*/
/*}*/

@media only screen and (max-width: 1130px) {
    div.post-page div.image-gallery-container>div.image-gallery img.image-gallery-image,
    div.post-page div.image-gallery-container>div.image-gallery.single img.image-gallery-image {
        height: 300px;
    }
}

@media only screen and (max-width: 900px) {
    div.post-page>div.section-container {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    div.post-page div.image-gallery-container>div.image-gallery img.image-gallery-image,
    div.post-page div.image-gallery-container>div.image-gallery.single img.image-gallery-image {
        height: 200px;
    }
}

@media only screen and (max-width: 500px) {
    div.post-page>div.section-container {
        width: 95%;
    }
}