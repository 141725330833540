div.posts-container{
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 5% 30px 5%;
}

div.post {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    max-height: 210px;
    margin-bottom: 30px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.543s ease-in-out;
}

div.post:last-child{
    margin-bottom: 0;
}

div.post.post-left {
    flex-direction: row-reverse;
}

div.post.post-empty {
    height: 210px;
    width: 80%;
}

.post-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.post>div {
    margin: 10px;
    flex-direction: column;
}

div.post div.post-image-container {
    flex: 1;
    min-width: 250px;
    overflow: hidden;
}

div.post div>div.image-gallery img.image-gallery-image {
    height: 130px;
    object-fit: cover;
}

div.post div>div.image-gallery.single img.image-gallery-image {
    height: 175px;
    object-fit: cover;
}

div.post div.post-content-container{
    flex: 2;
    min-width: 250px;
}

div.post div.post-content-container h2 {
    margin: 0 0 5px;
}

div.post div.post-content-container small.post-date {
    margin-bottom: 1em;
}

div.post div.post-content-container p {
    flex: 2;
}

div.post div.post-content-container>p * {
    font-size: medium;
}

.post-text {
    margin-bottom: 3em;
    overflow: hidden;
}

.post-text, .post-text * {
    font-size: 1em !important;
    text-align: left !important;
}

.post-text img {
    max-width: 100%;
    object-fit: scale-down;
}

.post-text div.after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top, var(--white-1) 30%, rgba(255,255,255, 0) 70%);
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.543s ease-in-out;
}

.disabled-content {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

div.post div.post-content-container div.post-text>div:first-of-type>* {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

div.post div.post-content-container div.post-text a.post-attachment:first-of-type {
    margin-top: 1.5em;
}

div.post div.post-content-container div.post-text a.post-attachment:last-of-type {
    margin-bottom: 1.5em;
}

div.post button.post-button{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10em;
    margin: 20px;
}

div.post.post-left button.post-button {
    right: unset;
    left: 0;
}

div.post iframe {
    max-width: 100%;
    width: initial;
    height: initial;
}

@media only screen and (min-width: 1600px) {
    div.post div.post-content-container {
        flex: 4;
    }
}

@media only screen and (max-width: 1130px) {
    .news{
        /*padding-bottom: 4em;*/
    }
}

@media only screen and (max-width: 960px) {
    div.post,
    div.post.post-empty{
        width: 80%;
    }
}

@media only screen and (max-width: 800px) {
    div.post,
    div.post.post-empty {
        width: 90%;
        padding: 2px;
    }

    div.posts-container {
        width: 95%;
    }
}

@media only screen and (max-width: 636px) {
    div.post {
        max-height: 420px;
    }

    .post-text div.after {
        background: linear-gradient(to top, var(--white-1) 30%, rgba(255, 255, 255, 0) 70%);
        height: 40%;
    }

    div.post.post-left button.post-button {
        right: 0;
        left: unset;
    }

    div.post div.post-image-container > div {
        flex: 1;
    }

    div.post div.post-image-container > div {
        min-width: 70px;
        /*min-height: 190px;*/
    }

    div.post div > div.image-gallery img.image-gallery-image {
        height: 230px;
    }

    div.post div>div.image-gallery.single img.image-gallery-image {
        height: 260px;
    }
}

@media only screen and (max-width: 550px) {
    div.post, div.post-empty {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    div.post div > div.image-gallery img.image-gallery-image {
        height: 170px;
    }

    div.post div>div.image-gallery.single img.image-gallery-image {
        height: 200px;
    }
}

@media only screen and (max-width: 370px) {
    div.post div.post-content-container {
        min-width: 200px;
    }
}