@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Rowdies:wght@300;400;700&display=swap');

:root {
    --font-dark: rgb(19, 15, 83);
    --white-1: rgb(246, 246, 246);
    --white-2: rgb(242, 242, 242);
    --accent-1: rgb(65, 111, 216);
    --accent-1-light: rgb(122, 158, 239);
    --accent-1-extra-light: rgb(195, 215, 252);
    --accent-1-faded: rgba(65, 111, 216, 0.36);
    --accent-1-lightly-faded: rgba(65, 111, 216, 0.61);
    --accent-2: rgb(238, 204, 35);
    --accent-2-light: rgb(241, 218, 105);
    --accent-2-lighter: rgb(238, 225, 160);
    --accent-2-faded: rgba(238, 204, 35, 0.38);
    --accent-2-lightly-faded: rgba(238, 204, 35, 0.53);
    --accent-3: rgba(112, 112, 112, 0.78);
    --accent-3-light: rgba(232, 232, 232, 1.0);

    --min-width-wide-pc: 1200px;
    --min-width-pc: 1130px;
    --min-width-tablet: 600px;

    --nav-bar-height: 0;
}

html {
    min-height: 100vh;
    -webkit-text-size-adjust: 100%;
}
body {
    font-family: "Nunito", sans-serif;
    min-width: 360px;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--white-1);
    color: var(--font-dark);
    font-size: 15px;
}


#root {
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    /*align-items: stretch;*/
    justify-content: space-between;
}

.content-container {
    flex: 2;
}

@media (max-width: 1130px) {
    .content-container {
        margin-top: 4em;
    }
}

*:focus {
    outline-style: none;
}

button.button-accent-1, button.button-accent-2 {
    height: auto;
    border-radius: 50px;
    padding: 8px 30px;
    font-family: "Rowdies", sans-serif;
    font-size: smaller;
    text-transform: uppercase;
    white-space: nowrap;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

button.button-accent-1 {
    background-color: var(--accent-1);
    box-shadow: 0 4px 8px 0 rgba(15, 49, 126, 0.2), 0 6px 20px 0 rgba(15, 49, 126, 0.19);
    color: var(--white-1);
}

button.button-accent-1:hover {
    background-color: var(--accent-1-light);
}

button.button-accent-2 {
    background-color: var(--accent-2);
    box-shadow: 0 4px 8px 0 rgba(105, 89, 5, 0.2), 0 6px 20px 0 rgba(105, 89, 5, 0.19);
    color: var(--font-dark);
}

button.button-accent-2:hover {
    background-color: var(--accent-2-light);
}

div.post h2{
    font-family: "Nunito", sans-serif;
    text-transform: none;

}

h1, h2, h3 {
    font-family: "Rowdies", sans-serif;
    text-transform: uppercase;
}

h1 {
    font-size: 1.2em;
    margin-top: 2em;
    margin-bottom: 1em;
}

h2 {
    font-size: 1.1em;
}

h3 {
    font-size: 1em;
}

small {
    font-size: 0.7em;
    font-weight: 300;
}

input {
    font-family: "Nunito", sans-serif;
    color: var(--font-dark) !important;
}

::-webkit-input-placeholder {
    color: var(--accent-1);
}
:-ms-input-placeholder {
    color: var(--accent-1);
}
::-ms-input-placeholder {
    color: var(--accent-1);
}
:-moz-placeholder {
    color: var(--accent-1);
}
::placeholder {
    color: var(--accent-1);
}

a {
    cursor: pointer;
}

a:link, a:visited {
    color: var(--accent-1);
}

a:hover {
    color: var(--accent-1-light);
}

a:active {
    color: var(--accent-1);
}

a.attachment, a.link{
    margin: 0.5em 0;
    padding: 0.5em 1em;
    color: var(--font-dark);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
}

a.link {
    background-color: var(--accent-1-extra-light);
}

a.attachment{
    background-color: var(--accent-2-lighter);
    z-index: 1;
}


a.attachment p, a.link p {
    font-size: smaller;
    margin: 0 1em 0 0;
    display: inline-block;
    text-align: left;
    color: var(--font-dark);
    word-break: break-word;
}

a.attachment svg, a.link svg {
    height: 1.2em;
    width: 1.2em;
    margin-left: 10px;
}

a.attachment svg * , a.link svg * {
    fill: var(--font-dark);
    color: var(--font-dark);
}
a.attachment div, a.link div {
    display: flex;
    align-items: center;
    justify-content: center;
}


a.attachment:hover *, a.link:hover * {
    color: var(--accent-1);
    fill: var(--accent-1);
}


.menu-item-active, .menu-item-active *{
    color: var(--accent-2) !important;
    fill: var(--accent-2) !important;
}

.image-button {
    cursor: pointer;
}

.image-button svg {
    height: 1.2em;
    width: auto;
}

.image-button:hover * {
    color: var(--accent-2);
    fill: var(--accent-2);
    stroke: var(--accent-2);
}

#spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4em;
    padding-bottom: 4em;
}

input {
    background-color: var(--white-1);
}

.video-responsive {
    padding: 20px;
    width: 72%;
    height: 500px;
    max-width: 940px;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

@media (max-width: 800px) {
    .video-responsive {
        height: 200px;
        width: 90%;
    }
}