

#error-image {
    margin-top: 8em;
    width: 60%;
    height: auto;
}

#error div>a {
    padding-top: 7em;
    padding-bottom: 3em;
}