div>div.image-gallery{
    width: 100%;
}

div>div.image-gallery * {
    outline: none !important;
}

div.image-gallery-slides div {
    width: 100%;
}

div>div.image-gallery.single .image-gallery-left-nav,
div>div.image-gallery.single .image-gallery-right-nav,
div>div.image-gallery.single .image-gallery-play-button,
div>div.image-gallery.single .image-gallery-thumbnails-wrapper,
div>div.image-gallery.single .image-gallery-thumbnails-wrapper *{
    display: none !important;
}

.image-gallery-svg {
    z-index: 0;
}

div>div.image-gallery.fullscreen-modal {
    padding: 0.5em 0;
}

div>div.image-gallery .fullscreen img.image-gallery-image {
    max-height: 85vh;
    width: auto !important;
    max-width: 100%;
    height: auto !important;
}

div>div.image-gallery div.image-gallery-thumbnails-wrapper {
    height: auto;
}

div>div.image-gallery div.image-gallery-thumbnails-container {
    justify-content: center;
}

div>div.image-gallery div.image-gallery-thumbnails-container button {
    flex: 1;
    height: 45px;
    width: 45px;
}

div>div.image-gallery .fullscreen div.image-gallery-thumbnails-container button {
    flex: 1;
    height: unset;
    width: unset;
}

.image-gallery-thumbnail {
    width: 45px;
}

/* .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    max-height: 100%;
    max-width: 100%;
} */

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

/*icons*/

.image-gallery-svg:focus, .image-gallery-svg:active {
    stroke: var(--white-1);
}

div>div.image-gallery .image-gallery-left-nav,
div>div.image-gallery .image-gallery-right-nav{
    padding: 0;
}

div>div.image-gallery .image-gallery-left-nav .image-gallery-svg,
div>div.image-gallery .image-gallery-right-nav .image-gallery-svg{
    height: 60px;
    width: 30px;
    margin: 0 4px;
}

div>div.image-gallery .image-gallery-fullscreen-button,
div>div.image-gallery .image-gallery-play-button {
    padding: 5px 10px;
}

div>div.image-gallery .image-gallery-fullscreen-button .image-gallery-svg,
div>div.image-gallery .image-gallery-play-button .image-gallery-svg {
    height: 20px;
    width: 20px;
}


@media screen and (min-width: 500px) {
    div>div.image-gallery .fullscreen .image-gallery-left-nav,
    div>div.image-gallery .fullscreen .image-gallery-right-nav{
        padding: 3px;
    }

    div>div.image-gallery .fullscreen .image-gallery-left-nav .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-right-nav .image-gallery-svg{
        height: 90px;
        width: 45px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-play-button .image-gallery-svg {
        height: 28px;
        width: 28px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button,
    div>div.image-gallery .fullscreen .image-gallery-play-button {
        padding: 8px 15px;
    }
}

@media screen and (min-width: 900px) {
    div>div.image-gallery .fullscreen .image-gallery-left-nav,
    div>div.image-gallery .fullscreen .image-gallery-right-nav{
        padding: 15px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button,
    div>div.image-gallery .fullscreen .image-gallery-play-button {
        padding: 20px 30px;
    }
}

@media screen and (min-width: 1130px) {
    div>div.image-gallery .fullscreen .image-gallery-left-nav,
    div>div.image-gallery .fullscreen .image-gallery-right-nav{
        padding: 10px;
    }

    div>div.image-gallery .fullscreen .image-gallery-left-nav .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-right-nav .image-gallery-svg{
        height: 120px;
        width: 60px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button .image-gallery-svg,
    div>div.image-gallery .fullscreen .image-gallery-play-button .image-gallery-svg {
        height: 36px;
        width: 36px;
    }

    div>div.image-gallery .fullscreen .image-gallery-fullscreen-button,
    div>div.image-gallery .fullscreen .image-gallery-play-button {
        padding: 20px 30px;
    }

    div>div.image-gallery .image-gallery-icon:hover {
        color: var(--accent-2-light);
    }
}

div>div.image-gallery .image-gallery-thumbnail.active,
div>div.image-gallery .image-gallery-thumbnail:hover,
div>div.image-gallery .image-gallery-thumbnail:focus {
    border-color: var(--accent-1);
}

@media only screen and (min-width: 1300px) {
    .post div>div.image-gallery {
        width: 100%;
        min-width: 200px;
    }
}