div.water-examine-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.water-examine-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

a.water-examine-attachment {
    width: 50%;
}


@media (max-width: 700px) {
    div.water-examine-container {
        width: 80%;
    }
}

@media (max-width: 500px) {
    div.water-examine-container {
        width: 90%;
    }

    a.water-examine-attachment {
        width: 70%;
    }
}

@media (max-width: 400px) {
    div.water-examine-container {
        width: 100%;
    }
}
